import axios from 'axios';
import * as config from '../config';

import { fetchSearchResults } from './tags';
import lodash from "lodash";
import { onError } from '@apollo/client/link/error';

export const SETISLOADINGFEEDERS = 'feeders/SETISLOADINGEDGELESSFEEDERS';
export const SETISADDINGFEEDERS = 'feeders/SETISADDINGEDGELESFEEDERS';
export const SETFEEDERS = 'feeders/SETEDGELESSFEEDERS';
export const SETISUPDATINGFEEDER = 'feeders/SETISUPDATINGEDGELESFEEDER';

export const getEdgelessFeeders = (onSuccess = () => { }, onError = () => { }, enableLoading = true) => {
  return (dispatch, getState) => {
    if (enableLoading) {
      dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: true });
    }
    dispatch({ type: SETISADDINGFEEDERS, isAddingFeeders: false });
    dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
    axios(`${config.endpoints.base_url}v3/feeders?identity=edgeless&type=meraki`).then((resp) => {
      if (enableLoading) {
        dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: false });
      }
      dispatch({ type: SETFEEDERS, feeders: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      if (enableLoading) {
        dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: false });
      }
      onError(err);
    })
  }
}

export const getAllFeeders = (onSuccess = () => { }, onError = () => { }, enableLoading = true) => {
  return (dispatch, getState) => {
    if (enableLoading) {
      dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: true });
    }
    dispatch({ type: SETISADDINGFEEDERS, isAddingFeeders: false });
    dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
    axios(`${config.endpoints.base_url}v3/feeders?is_updated=1`).then((resp) => {
      if (enableLoading) {
        dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: false });
      }
      dispatch({ type: SETFEEDERS, feeders: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      if (enableLoading) {
        dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: false });
      }
      onError(err);
    })
  }
}

export const editEdgelessFeeder = (data, onSuccess = () => { }, onError = () => { }) => {
  let payload = {
    "name": data.name,
    "description": data.description,
    "api_key": data.api_Key,
    "site_id": data?.site?.id,
    "floor_id": data.floor?.id,
    "location_id": data.zone?.id,
    "tags": [],
    "apps": data?.service_applets.map(sa => sa.code) || [],
    "camera": {
      fov_height: data?.height,
      fov_height_unit: data?.unit?.value,
      fov_angle: data?.angle,
      fov_install_angle: data?.install_angle,
      fov_type: data?.fov_type?.value
    }
  };

  if(data.vendor) {
    payload.vendor = data.vendor;
  }

  return async (dispatch, getState) => {
    if (data?.tags?.length > 0) {
      let tagList = getState().tags.tags;
      let newTags = [];
      let currentTags = [];
      data.tags.forEach(tag => {
        let hasValue = tagList.find(t => t.name === tag);
        if (hasValue) {
          currentTags.push(hasValue.id);
        } else {
          newTags.push(tag);
        }
      });

      if (newTags.length > 0) {
        await Promise.all(newTags.map((tag) => createTagPromise(tag)))
          .then((res) => {
            res.forEach(r => {
              currentTags.push(r.data.data.id);
            });
            payload.tags = currentTags;
          })
          .catch(err => {
            console.log('err', err)
          });
      } else {
        payload.tags = currentTags;
      }
    }

    dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: true });
    verifyKeys([data], payload.vendor,  (res) => {
      if (res.data[0]?.is_verified) {
        axios.put(`${config.endpoints.base_url}v3/feeders/${data.id}`, payload)
          .then((resp) => {
            let count = 0;
            let polling = setInterval(() => {
              checkAddStatusWithEddie(resp.data.data, (res) => {
                count += 1;
                if (res?.data?.status === "Completed" && res?.data?.type === 'success') {
                  dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
                  let updatedFeeder = resp.data.data;
                  let currentFeeders = getState().edgelessfeeders?.feeders;
                  let oldCopy = lodash.cloneDeep(currentFeeders[currentFeeders.map(f => f.id).indexOf(updatedFeeder.id)]);
                  currentFeeders[currentFeeders.map(f => f.id).indexOf(updatedFeeder.id)] = { ...oldCopy, ...updatedFeeder }; // retain data that are not in updatedFeeder
                  dispatch({ type: SETFEEDERS, feeders: currentFeeders });
                  clearInterval(polling);
                  return onSuccess(resp.data);
                } else {
                  if (count === 10) {
                    clearInterval(polling);
                    dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
                    onError({
                      msg: 'fail',
                      status: false,
                    });
                  }
                }
              })
            }, 1000)
          })
          .catch(err => {
            dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
            onError({
              msg: err?.response?.data?.message,
              status: false,
            })
          })
      } else {
        dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
        if (res.data[0]?.error === 'Invalid API Key') {
          onError({
            msg: 'fail',
            status: true, // has response
            invalid_api_key: res.data[0]?.api_key
          })
        } else {
          onError({
            msg: res.data[0]?.error ? res.data[0]?.error : 'fail',
            status: false, // err response
          })
        }
      }
    })
  }
}

export const editAxisEdgelessFeeder = (data, onSuccess = () => { }, onError = () => { }) => {
  let payload = {
    "name": data.name,
    "description": data.description,
    "api_key": data.api_Key,
    "site_id": data?.site?.id,
    "floor_id": data.floor?.id,
    "location_id": data.zone?.id,
    "tags": [],
    "apps": data?.service_applets.map(sa => sa.code) || [],
    "camera": {
      fov_height: data?.height,
      fov_height_unit: data?.unit?.value,
      fov_angle: data?.angle,
      fov_install_angle: data?.install_angle,
      fov_type: data?.fov_type?.value
    },
    "vendor": "axis"
  };

  return async (dispatch, getState) => {
    if (data?.tags?.length > 0) {
      let tagList = getState().tags.tags;
      let newTags = [];
      let currentTags = [];
      data.tags.forEach(tag => {
        let hasValue = tagList.find(t => t.name === tag);
        if (hasValue) {
          currentTags.push(hasValue.id);
        } else {
          newTags.push(tag);
        }
      });

      if (newTags.length > 0) {
        await Promise.all(newTags.map((tag) => createTagPromise(tag)))
          .then((res) => {
            res.forEach(r => {
              currentTags.push(r.data.data.id);
            });
            payload.tags = currentTags;
          })
          .catch(err => {
            console.log('err', err)
          });
      } else {
        payload.tags = currentTags;
      }
    }

    dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: true });
    axios.put(`${config.endpoints.base_url}v3/feeders/axis/${data.id}`, payload)
    .then((resp) => {
      let updatedFeeder = resp.data.data;
      let currentFeeders = getState().edgelessfeeders?.feeders;
      let oldCopy = lodash.cloneDeep(currentFeeders[currentFeeders.map(f => f.id).indexOf(updatedFeeder.id)]);
      currentFeeders[currentFeeders.map(f => f.id).indexOf(updatedFeeder.id)] = { ...oldCopy, ...updatedFeeder };
      dispatch({ type: SETFEEDERS, feeders: currentFeeders });
      return onSuccess(resp.data);
    })
    .catch(err => {
      dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
      onError({
        msg: err?.response?.data?.message,
        status: false,
      })
    })
  }
}


export const addEdgelessFeeders = (data, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {
    const { sensors, vendor } = data;
    let network_id = getState().networks?.currentNetwork?.id;
    let currentFeeders = getState().edgelessfeeders?.feeders;
    dispatch({ type: SETISADDINGFEEDERS, isAddingFeeders: true });

    verifyKeys(sensors, vendor,  (res) => {
      let not_verified_sensor_keys = res.data.filter(d => d.is_verified === false);

      if (not_verified_sensor_keys.length > 0) {
        dispatch({ type: SETISADDINGFEEDERS, isAddingFeeders: false });
        let api_key_errors = [];
        let serial_key_errors = [];
        not_verified_sensor_keys.forEach(k => {
          if (k.error === 'Invalid Serial Id') {
            serial_key_errors.push(k.serial_id);
          } else {
            api_key_errors.push(k.api_key);
          }
        })
        return onError({
          status: true,
          msg: 'Error on verifying api keys and serial id',
          api_key_errors,
          serial_key_errors
        })
      } else {
        Promise.allSettled(sensors.map((sensor) => addSensorPromise(sensor, vendor, network_id)))
          .then((res) => {
            let failedFeeders = res.filter(f => f.status === "rejected").map(d => JSON.parse(d?.reason));
            let newFeeders = res.filter(f => f.status === "fulfilled").map(d => d?.value?.data);
            dispatch({ type: SETFEEDERS, feeders: newFeeders.concat(currentFeeders) });
            dispatch({ type: SETISADDINGFEEDERS, isAddingFeeders: false });

            return onSuccess({ status: true, msg: 'success', feeders: newFeeders, failedFeeders })
          })
          .catch(err => {
            dispatch({ type: SETISADDINGFEEDERS, isAddingFeeders: false });
            onError({
              status: false,
              msg: 'An error has occured while adding sensors'
            })
            console.log('errhere', err)
          });
      }
    })
  }
}

export const disconnectFeeder = ({ id, onAddError }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {
    axios.delete(`${config.endpoints.base_url}v3/feeders/${id}/delete_from_sensei`).then((resp) => {
      let count = 0;
      let checkStatus = (function recurse() {
        checkAddStatusWithEddie({ id }, (res) => {
          count += 1;
          if (res?.data?.status === "Completed" && res?.data?.type === 'success') {
            axios.delete(`${config.endpoints.base_url}v2/feeders/${id}/delete_from_portal`).then((resp) => {
              let updatedFeeders = getState().edgelessfeeders?.feeders;
              let removedFeederIndex = updatedFeeders?.map(d => d.id).indexOf(id);
              updatedFeeders.splice(removedFeederIndex, 1);
              dispatch({ type: SETFEEDERS, feeders: updatedFeeders });
              return onSuccess(resp.data);
            }).catch((err) => {
              onError({ msg: 'fail', status: false })
            })
          } else {
            if (count === 50) {
              onError({ msg: 'fail', status: false })
            } else {
              recurse();
            }
          }
        }, (err) => {
          console.log('error', err)
        })
      }());
    }).catch((err) => {
      onError({ msg: 'fail', status: false })
    })
  }
}

const createTagPromise = (tag) => {
  return new Promise((resolve, reject) => {
    axios.post(`${config.endpoints.base_url}tags`, { name: tag })
      .then((resp) => {
        resolve(resp);
      })
      .catch(err => {
        console.log('err2', err);
        reject(err);
      })
  });
}

const addSensorPromise = (sensor, vendor, network_id) => {
  return new Promise((resolve, reject) => {
    let payload = {
      "name": sensor.name,
      "serial_id": sensor.serial_ID,
      "api_key": sensor.api_Key,
      "network_id": network_id
    }

    if(vendor) {
      payload.vendor = vendor;
    }
    axios({
      method: 'post',
      url: `${config.endpoints.base_url}v3/feeders`,
      data: payload
    })
      .then(resp => resolve(resp.data))
      .catch(err => {
        if (err?.request?.response !== (undefined || null || '')) {
          let errRes = JSON.parse(err.request.response);
          errRes = {
            ...errRes,
            ...sensor
          }
          if (typeof errRes.message === 'object' && errRes.message !== null) {
            errRes.message = 'An error has occured';
          }
          reject(JSON.stringify(errRes));
        } else {
          reject(err);
        }
      })
  })
};

const verifyKeys = (sensors, vendor, cb) => {
  let url = `${config.endpoints.base_url}v3/feeders/verifyserialkey`;
  if(vendor) {
    url = `${config.endpoints.base_url}v3/feeders/verifyserialkey?vendor=${vendor}`;
  }
  axios({
    method: 'post',
    url: url,
    data: sensors.map(s => ({
      serial_id: s.serial_ID,
      api_key: s.api_Key,
    }))
  })
    .then(resp => cb(resp.data))
    .catch(err => console.log('verifySerialKey err', err))
}

export const checkAddStatusWithEddie = (sensor, onSuccess, onError) => {
  axios({
    method: 'get',
    url: `${config.endpoints.base_url}v3/feeders/${sensor.id}/actions`,
  })
    .then(resp => onSuccess(resp.data))
    .catch(err => onError(err))
}

export const addEdgeFeeder = (data, onSuccess, onError) => {
  return async (dispatch, getState) => {
    const sensor_details = data.sensorDetails;
    const device = data.connectSensor.device;
    const sensor_type = data.connectSensor?.sensor_type?.value;
    const sa = data.serviceApplets && data.serviceApplets.map(d => d.code) || [];
    const apps = data.apps && data.apps.map(d => d.code) || [];
    const payload = {
      type: 'edge',
      sensor_type: sensor_type,
      apps: [...sa, ...apps],
      name: sensor_details.sensorName,
      device_id: device.id,
      location_id: sensor_details.zone.id,
      site_id: sensor_details.site.id,
      camera: {
        ...data.connectSensor.sensor,
        name: sensor_details.sensorName,
        apps: [...sa, ...apps],
        fov_height: data.sensorDetails?.height,
        fov_height_unit: data.sensorDetails?.unit?.value,
        fov_angle: data.sensorDetails?.angle,
        fov_install_angle: data.sensorDetails?.install_angle,
        fov_type: data.sensorDetails?.fov_type?.value,
        is_added: true
      },
      vendor:data.connectSensor.vendor,
      description: sensor_details.description
    };
    if (sensor_details?.tags?.length > 0) {
      let tagList = getState().tags.tags;
      let newTags = [];
      let currentTags = [];
      sensor_details.tags.forEach(tag => {
        let hasValue = tagList.find(t => t.name === tag);
        if (hasValue) {
          currentTags.push(hasValue.id);
        } else {
          newTags.push(tag);
        }
      });

      if (newTags.length > 0) {
        await Promise.all(newTags.map((tag) => createTagPromise(tag)))
          .then((res) => {
            res.forEach(r => {
              currentTags.push(r.data.data.id);
            });
            payload.tags = currentTags;
          })
          .catch(err => {
            console.log('err', err)
          });
      } else {
        payload.tags = currentTags;
      }
    }

    axios({ method: 'post', url: `${config.endpoints.base_url}v4/feeders`, data: payload, params: { send_device_action: 1 } })
      .then(resp => {
        let count = 0;
        const res_device_action = resp.data.data;
        let checkStatus = (function recurse() {
          getDeviceActions(device?.id, ({ data }) => {
            count += 1;
            const { rows } = data;
            const filtered_actions = lodash.chain(rows)
              .filter({ 'session_token': res_device_action.session_token })
              .filter({ 'action': res_device_action.action })
              .value();
            const deviceAction = lodash.first(filtered_actions);
            console.log('deviceAction', deviceAction?.status)
            if (deviceAction?.status === 'Completed') {
              axios({ method: 'post', url: `${config.endpoints.base_url}v4/feeders`, data: payload, params: { send_device_action: 0 } })
                .then(() => {
                  dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
                  return onSuccess(resp.data);
                })
                .catch(err => {
                  return onError({ msg: 'An unexpected error has occured while adding your sensor', status: false });
                })
            } else {
              if (count === 100) {
                dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
                onError({ msg: 'Looks like the server is taking too long to respond, please try again in sometime.', status: false });
              } else {
                recurse();
              }
            }
          },
            (error) => {
              console.log(error);
              dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
              onError({ msg: error?.response?.data?.message || 'An unexpected error has occured while adding your sensor', status: false })
            });
        }());
      })
      .catch(err => {
        if (err.message) {
          if (err.response?.data?.message) {
            if (err?.response?.data?.message && typeof err?.response?.data?.message === 'string') {
              onError({ msg: err?.response?.data?.message || 'An erron has occured while adding your sensor', status: false })
            } else {
              onError({ msg: 'An erron has occured while adding your sensor', status: false })
            }

          } else {
            onError({ msg: err.message, status: false })
          }
        } else {
          onError({ msg: 'An unexpected error has occured', status: false })
        }
      })
  }
}

export const editEdgeFeeder = (data, onSuccess = () => { }, onError = () => { }) => {
  let payload = {
    "name": data.name,
    "type": 'edge',
    "description": data.description,
    "site_id": data?.site?.id,
    "floor_id": data.floor?.id,
    "location_id": data.zone?.id,
    "tags": [],
    "apps": data?.service_applets?.map(sa => sa.code) || [],
    "camera": {
      ...data.camera,
      name: data.name,
      apps: data?.service_applets?.map(sa => sa.code) || [],
      fov_height: data?.height,
      fov_height_unit: data?.unit?.value,
      fov_angle: data?.angle,
      fov_install_angle: data?.install_angle,
      fov_type: data?.fov_type?.value
    },
    "vendor": data?.vendor ? data?.vendor : null
  };
  let sensor_id = data.id;

  return async (dispatch, getState) => {
    if (data?.tags?.length > 0) {
      let tagList = getState().tags.tags;
      let newTags = [];
      let currentTags = [];
      data.tags.forEach(tag => {
        let hasValue = tagList.find(t => t.name === tag);
        if (hasValue) {
          currentTags.push(hasValue.id);
        } else {
          newTags.push(tag);
        }
      });

      if (newTags.length > 0) {
        await Promise.all(newTags.map((tag) => createTagPromise(tag)))
          .then((res) => {
            res.forEach(r => {
              currentTags.push(r.data.data.id);
            });
            payload.tags = currentTags;
          })
          .catch(err => {
            console.log('err', err)
          });
      } else {
        payload.tags = currentTags;
      }
    }

    dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: true });
    axios({ method: 'put', url: `${config.endpoints.base_url}v4/feeders/${sensor_id}`, data: payload, params: { send_device_action: 1 } })
      .then((resp) => {
        let count = 0;
        const device = resp.data.data.device_action;


        let checkStatus = (function recurse() {
          getDeviceActions(device?.device_id, ({ data }) => {
            count += 1;
            const { rows } = data;
            const filtered_actions = lodash.chain(rows)
              .filter({ 'session_token': device.session_token })
              .filter({ 'action': device.action })
              .value();

            const deviceAction = lodash.first(filtered_actions);
            console.log('deviceAction', deviceAction?.status)
            if (deviceAction?.status === 'Completed') {
              axios({ method: 'put', url: `${config.endpoints.base_url}v4/feeders/${sensor_id}`, data: payload, params: { send_device_action: 0 } })
                .then((resp) => {
                  dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
                  let updatedFeeder = resp.data.data;
                  updatedFeeder.vendor = payload?.vendor
                  let currentFeeders = getState().edgelessfeeders?.feeders;
                  let oldCopy = lodash.cloneDeep(currentFeeders[currentFeeders.map(f => f.id).indexOf(updatedFeeder.id)]);
                  currentFeeders[currentFeeders.map(f => f.id).indexOf(updatedFeeder.id)] = { ...oldCopy, ...updatedFeeder }; // retain data that are not in updatedFeeder
                  dispatch({ type: SETFEEDERS, feeders: currentFeeders });
                  return onSuccess(resp.data);
                })
                .catch(err => {
                  dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
                  return onError({
                    msg: 'An error has occured while editing your sensor.',
                    status: false,
                  })
                })

            } else {
              if (count === 100) {
                dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
                onError({
                  msg: 'An error has occured while editing your sensor.',
                  status: false,
                });
              } else {
                recurse()
              }
            }
          },
            (err) => {
              console.log(err);
              dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
              onError({
                msg: 'An error has occured while editing your sensor.',
                status: false,
              })
            }
          );
        }());
      })
      .catch(err => {
        dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
        onError({
          msg: err?.message,
          status: false,
        })
      })
  }
}

export const disconnectEdgeFeeder = ({ id, onAddError }, onSuccess = () => { }, onError = () => { }) => {
  return async (dispatch, getState) => {
    axios.delete(`${config.endpoints.base_url}v4/feeders/${id}`, { params: { send_device_action: 1 } }).then((resp) => {
      const device = resp.data.data.device_action;
      let count = 0;
      let checkStatus = (function recurse() {
        getDeviceActions(device?.device_id, ({ data }) => {
          count += 1;
          const { rows } = data;
          const filtered_actions = lodash.chain(rows)
            .filter({ 'session_token': device.session_token })
            .filter({ 'action': device.action })
            .value();
          const deviceAction = lodash.first(filtered_actions);
          if (deviceAction?.status === 'Completed') {
            axios.delete(`${config.endpoints.base_url}v4/feeders/${id}`, { params: { send_device_action: 0 } }).then((resp) => {
              let updatedFeeders = getState().edgelessfeeders?.feeders;
              let removedFeederIndex = updatedFeeders?.map(d => d.id).indexOf(id);
              updatedFeeders.splice(removedFeederIndex, 1);
              dispatch({ type: SETFEEDERS, feeders: updatedFeeders });
              return onSuccess(resp.data);
            }).catch((err) => {
              onError({ msg: err.message, status: false })
            })
          } else {
            if (count === 60) {
              onError({ msg: 'An error has occured while disconnecting your sensor', status: false })
            } else {
              recurse();
            }
          }
        }, (err) => {
          onError({ msg: err.message, status: false })
        })
      }());
    }).catch((err) => {
      let err_data = err?.response?.data;
      let msg = "An error has occured while disconnecting your sensor";
      if (err_data) {
        msg = err_data?.message !== "" ? err_data.message : "An error has occured while disconnecting your sensor";
      }
      onError({ msg, status: false })
    })
  }
}


export const getDeviceActions = (id, onSuccess = () => { }, onError = () => { }) => {
  axios.get(
    `${config.endpoints.base_url}v3/devices/${id}/actions?limit=20`
  ).then((resp) => {
    return onSuccess(resp.data);
  }).catch((err) => {
    return onError(err);
  })
}

export const getAllFeeders2 = (onSuccess = () => { }, onError = () => { }, enableLoading = true) => {
  return (dispatch, getState) => {
    if (enableLoading) {
      dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: true });
    }
    dispatch({ type: SETISADDINGFEEDERS, isAddingFeeders: false });
    dispatch({ type: SETISUPDATINGFEEDER, isUpdatingFeeder: false });
    axios(`${config.endpoints.base_url}v3/feeders`).then((resp) => {
      if (enableLoading) {
        dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: false });
      }
      dispatch({ type: SETFEEDERS, feeders: resp.data.data.rows });
      return onSuccess(resp.data);
    }).catch((err) => {
      if (enableLoading) {
        dispatch({ type: SETISLOADINGFEEDERS, isLoadingFeeders: false });
      }
      onError(err);
    })
  }
}


export const generatePairingCode = (onSuccess = () => { }, onError = () => { }) => {
  axios.post(
    `${config.endpoints.base_url}v3/feeders/codes/pairing`
  ).then((resp) => {
    return onSuccess(resp.data);
  }).catch((err) => {
    return onError(err);
  })
}



export const downloadAxisApp = (onSuccess = () => { }, onError = () => { }) => {
  axios.get(
    `${config.endpoints.base_url}v3/feeders/axis/download`
  ).then((resp) => {
    return onSuccess(resp.data);
  }).catch((err) => {
    return onError(err);
  })
}

export const getApiKey = (id) => {
  return axios({
    method: 'get',
    url: `${config.endpoints.base_url}v3/feeders/${id}`,
  })
    .then(resp => {
     return resp;
    })
    .catch(err => {return err})
}
