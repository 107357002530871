import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import { KeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { PersistGate } from 'redux-persist/integration/react';
import axios from 'axios';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import configureStore, { history } from './store/store';
import { addIconLibrary } from './actions/icons';
import { resolveClient } from './graphql/resolver';
import { QueryClient, QueryClientProvider } from 'react-query';

import './App.scss';

import keycloak, { auth } from './config';
import { getMyNetwork } from './actions/networks';
import { KeycloakInitOptions } from 'keycloak-js';
import jwt_decode from 'jwt-decode';

const keycloakProviderInitConfig: KeycloakInitOptions = {
  // onLoad: 'check-sso',
  onLoad: 'login-required',
  checkLoginIframe: false,
};

export const { persistor, store } = configureStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

const loading = () => (
  <div className="animated fadeIn pt-3 text-center d-none">
    <i className="fas d-none fa-redo fa-spin" />
  </div>
);

const AdminRoute = React.lazy(() => import('./containers/AdminLayout/AdminLayout'));
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const Logout = React.lazy(() => import('./views/Pages/Logout/Logout'));
const EndUserTerms = React.lazy(() => import('./views/Pages/EndUserTerms/EndUserTerms'));
const Register = React.lazy(() => import('./views/Pages/Register/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const Preloader = React.lazy(() => import('./views/Home/Preloader'));
const queryClient = new QueryClient();

addIconLibrary();

axios.interceptors.request.use(function (config) {
  try {
    
    if(!config?.url?.includes(auth.auth_url)){
      let currentNetwork = localStorage.getItem('currentNetwork');
      if(currentNetwork != null && currentNetwork != '' && currentNetwork != 'undefined'){
        config.headers.common['X-NETWORK-ID'] = currentNetwork;
      }
    }

  } catch (error) {}

  return config;
});

function AppRouter({ client }) {
  const [, initialized] = useKeycloak();
  const [isNetworkSet, setIsNetworkSet] = useState(false);

  useEffect(() => {
    if (initialized) {
      setNetwork();
    }

    let currentNetwork = localStorage.getItem('currentNetwork');
    if(currentNetwork == null || currentNetwork == '' || currentNetwork == 'undefined'){
      history.push({ pathname: "/home"});
    }

  },[initialized])

  const setNetwork = async () => {
    let currentNetwork = localStorage.getItem('currentNetwork');
    let recentNetworks = localStorage.getItem('recentNetworks');
    const parsedRecentNetworks = recentNetworks ? JSON.parse(recentNetworks) : null

    const urlParams = new URLSearchParams(window.location.search);

    const networkParam = urlParams.get('networkParam');
    if (networkParam != null && networkParam != '') {
      currentNetwork = networkParam;
      localStorage.setItem('currentNetwork', networkParam);
    }

    //setup recent network
    try {
      const accessToken = localStorage.getItem('access_token');
      // let auth = null
      let auth: {email: string, networks: string[]} | null = null
      if (accessToken !== undefined) {
        try {
          auth = jwt_decode(accessToken);
          if(currentNetwork == null || currentNetwork == undefined) {
            if(auth?.networks[0]) {
              localStorage.setItem('currentNetwork', auth?.networks[0])
              currentNetwork = auth?.networks[0];
            }
            
          }
        }
        catch (e) {}
      }
      
      if(parsedRecentNetworks){
        if(parsedRecentNetworks?.user == undefined ||  parsedRecentNetworks?.recentNetworks == undefined){
          localStorage.setItem('recentNetworks', JSON.stringify({user:auth?.email, recentNetworks:[]}));
        } else {
          if(parsedRecentNetworks?.user != auth?.email){
            localStorage.setItem('recentNetworks', JSON.stringify({user:auth?.email, recentNetworks:[]}));
          }
        }
        
        
      } else {
        localStorage.setItem('recentNetworks', JSON.stringify({user:auth?.email, recentNetworks:[]}));
      }
      
      
      
    } catch (error) {
      localStorage.setItem('recentNetworks', JSON.stringify({user:auth.email, recentNetworks:[]}));
    }


    try {
      const getMyNetworkResp = await getMyNetwork();
      let doesExist = false;
      getMyNetworkResp.data.map((network)=>{
        if(currentNetwork == network.name){
          doesExist = true;
        }
      });

      if(!doesExist){
        try {
          localStorage.setItem('currentNetwork', getMyNetworkResp.data[0].name)
        } catch (error) {}
      }

      setIsNetworkSet(true)
    } catch (error) {
      setIsNetworkSet(true)
    }


  };

  /*
    Checks keycloak is initialized and networks are loaded and set
    if not display empty page

    DEV notes:
    - Comment these lines so that `history` can be accessed before rendering
    - Uncomment these lines if whitescreens are persistent due to API are called first before setting network (race condition)
  */

  // if (!initialized || !isNetworkSet) {
  //   return <div />;
  // }

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ConnectedRouter history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" render={(props) => <Login {...props} />} />
              <Route exact path="/logout" render={(props) => <Logout {...props} />} />
              <Route exact path="/enduserterms" render={(props) => <EndUserTerms {...props} />} />
              <Route exact path="/register" render={(props) => <Register {...props} />} />
              <Route exact path="/404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/500" render={(props) => <Page500 {...props} />} />
              <Route exact path="/home" render={(props) => <Preloader {...props} />} />
              <Route path="/" render={(props) => <AdminRoute {...props} />} />
            </Switch>
          </React.Suspense>
        </ConnectedRouter>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

function App() {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | null | boolean>(null);

  return (
    <KeycloakProvider
      keycloak={keycloak}
      initConfig={keycloakProviderInitConfig}
      onEvent={(event, error) => {
        switch (event) {
          case 'onAuthSuccess':
            break;
          case 'onAuthLogout':
            break;
          case 'onTokenExpired':
            keycloak
              .updateToken(5)
              .then(() => {
                // console.log('successfully get a new token', keycloak.token);
              })
              .catch(() => {
                history.push('/login');
                keycloak.logout();
              });
            break;
          // case 'onInitError':
          //   break;
          case 'onAuthError':
            setClient(true);
            break;
          default:
            break;
        }
      }}
      onTokens={(tokens) => {
        localStorage.setItem('access_token', tokens.token);
        axios.defaults.headers.common.Authorization = `Bearer ${tokens.token}`;
        setClient(resolveClient(tokens.token, keycloak));
      }}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {client && <AppRouter client={client} />}
        </PersistGate>
      </Provider>
    </KeycloakProvider>
  );
}

export default App;